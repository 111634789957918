// Import headers and footers
import "./_header.js";
import "./_footer.js";

// Import Bootstrap
import { Dropdown, Modal, Tooltip, Carousel, Popover } from "bootstrap";

window.Carousel = Carousel;

// Import Firebase components
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator, collection, addDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

// Initialize Firebase
initializeApp(require("./_firebase_config"));

// Initialize Analytics
const analytics = getAnalytics();

//
// Autoplay YouTube videos in modals
//
const videoModal = document.querySelector("#videoModal");
if (videoModal) {
  document.querySelectorAll(".btn-video[data-src]").forEach((btn) => {
    btn.addEventListener("click", (e) => {
      videoModal.querySelector("iframe").setAttribute("src", e.target.getAttribute("data-src"));
      logEvent(analytics, "play_video");
    });
  });

  // Stop YouTube video from playing when modal dialogue is closed
  videoModal.addEventListener("hidden.bs.modal", function () {
    videoModal.querySelector("iframe").setAttribute("src", "");
  });
}

//
// Show a shadow under the navbar if not at the top
//
const ioCallback = (entries) => {
  entries.forEach((entry) => {
    document.getElementById("nav-shadow").style.opacity = entry.isIntersecting ? 0 : 0.05;
  });
};
const io = new IntersectionObserver(ioCallback, { threshold: 1 });
io.observe(document.getElementById("nav-spacer"));

//
// Lead Form
//
const leadForm = document.getElementById("lead-form");
if (leadForm) leadForm.addEventListener("submit", leadFormSubmit);

function leadFormSubmit(e) {
  e.preventDefault();
  const form = leadForm;

  fetchLeadFormData(form)
    .then(validateLeadFormData)
    .then(sendLeadFormNotification)
    .then(() => {
      form.querySelector("#form-body").classList.add("d-none");
      form.querySelector("#insert-first-name").innerHTML = form.firstName.value;
      form.querySelector("#success-message").classList.remove("collapse");
      form.querySelector("#error-message").classList.add("collapse");
    })
    .catch((error) => {
      console.error(error);
      if (error.indexOf("PERMISSION_DENIED") == 0) {
        error = "Something in one of the values you entered is invalid. Sorry, we don't have any more information that this!";
      }
      form.querySelector("#error-message").textContent = error;
      form.querySelector("#error-message").classList.remove("collapse");
    });
}

function fetchLeadFormData(form) {
  console.log("fetchLeadFormData");
  return new Promise(function (resolve, reject) {
    resolve({
      firstName: form.firstName.value.trim(),
      lastName: form.lastName.value.trim(),
      email: form.email.value.trim().toLowerCase(),
      phoneNumber: form.phoneNumber.value.replace(/\D/g, "") || null,
      message: form.message.value.trim() || null,
      company: form.company ? form.company.value.trim() || null : null,
      role: form.role ? form.role.value.trim() || null : null,
      source: form.source.value.trim() || null,
      pdf: form.pdf ? form.pdf.value.trim() || null : null,
      subject: form.subject ? form.subject.value.trim() || null : null,
    });
  });
}

function validateLeadFormData(data) {
  console.log("validateLeadFormData");
  return new Promise(function (resolve, reject) {
    if (data.email.length == 0) {
      reject("Enter your email.");
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email.trim())) {
      reject("Enter a valid email.");
    } else if (
      ["gmail.com", "outlook.com", "hotmail.com", "me.com", "icloud.com", "yahoo.com", "yahoo.com.au", "bigpond.com", "bigpond.net.au", "tpg.com.au", "iinet.com.au", "internode.com.au", "optus.com.au", "dodo.com.au", "iprimus.com.au"].includes(
        data.email.substring(data.email.indexOf("@") + 1).toLowerCase
      )
    ) {
      reject("Enter your work email. Can't be a free email provider.");
    } else if (data.firstName.length == 0) {
      reject("Enter your first name.");
    } else if (data.lastName.length == 0) {
      reject("Enter your last name.");
    } else if (data.phoneNumber && (data.phoneNumber.length < 10 || data.phoneNumber.length > 16)) {
      reject("Enter a valid mobile number.");
    } else if (data.message && data.message.length > 1000) {
      reject("Message must be less than 2,000 characters.");
    } else {
      resolve(data);
    }
  });
}

function sendLeadFormNotification(data) {
  console.log("sendLeadFormNotification");
  return new Promise(function (resolve, reject) {
    // Set up Firebase database
    const db = getFirestore();
    if (location.hostname === "localhost") connectFirestoreEmulator(db, "localhost", 8080);

    // Get collection Reference
    const colRef = collection(db, "mail");

    // Get the action
    const action = data.pdf ? "pdf" : "info-pack";

    addDoc(colRef, {
      to: "support@telltouch.com.au",
      replyTo: data.email,
      template: {
        name: `notification-${action}`,
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          company: data.company,
          role: data.role,
          email: data.email,
          phoneNumber: data.phoneNumber,
          message: data.message,
          time: null,
          date: null,
          pdf: data.pdf || null,
          method: null,
          source: data.source,
          subject: data.subject || "Tell Touch",
        },
      },
    })
      .then(() => {
        // deepcode ignore GlobalReplacementRegex: We only want to change the first occurrence
        logEvent(analytics, `request_${action.replace("-", "_")}`, {
          source: data.source,
        });
        // deepcode ignore GlobalReplacementRegex: We only want to change the first occurrence
        logEvent(analytics, "goal_completion", { name: `request_${action.replace("-", "_")}` });
        resolve();
      })
      .catch((error) => {
        reject(error.message);
      });
  });
}

//
// Contact Form
//
const contactForm = document.getElementById("contact-form");

// Listen to form submits
if (contactForm) contactForm.addEventListener("submit", contactFormSubmit);

function contactFormSubmit(e) {
  e.preventDefault();
  const form = contactForm;

  fetchContactFormData(form)
    .then(validateContactFormData)
    .then(sendContactFormNotification)
    .then(() => {
      form.querySelector("#form-body").classList.add("d-none");
      form.querySelector("#insert-first-name").innerHTML = form.name.value.split(" ").slice(0, -1).join(" ");
      form.querySelector("#success-message").classList.remove("collapse");
      form.querySelector("#error-message").classList.add("collapse");
    })
    .catch((error) => {
      console.error(error);
      if (error.indexOf("PERMISSION_DENIED") == 0) {
        error = "Something in one of the values you entered is invalid. Sorry, we don't have any more information that this!";
      }
      form.querySelector("#error-message").textContent = error;
      form.querySelector("#error-message").classList.remove("collapse");
    });
}

function fetchContactFormData(form) {
  console.log("fetchContactFormData");
  return new Promise(function (resolve, reject) {
    resolve({
      firstName: form.name.value.split(" ").slice(0, -1).join(" "),
      lastName: form.name.value.split(" ").slice(-1).join(" "),
      email: form.email.value.trim().toLowerCase(),
      phoneNumber: form.phoneNumber.value.replace(/\D/g, "") || null,
      message: form.message.value.trim() || null,
      subject: form.subject.value || null,
      source: form.source.value.trim() || null,
    });
  });
}

function validateContactFormData(data) {
  console.log("validateContactFormData");
  return new Promise(function (resolve, reject) {
    if (data.subject == null) {
      reject("Select a subject.");
    } else if (data.message == null) {
      reject("Enter a message.");
    } else if (data.firstName.length == 0 || data.lastName.length == 0) {
      reject("Enter your first and last names.");
    } else if (data.email.length == 0) {
      reject("Enter your email.");
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email.trim())) {
      reject("Enter a valid email.");
    } else if (data.phoneNumber && (data.phoneNumber.length < 10 || data.phoneNumber.length > 16)) {
      reject("Enter a valid mobile number.");
    } else if (data.message && data.message.length > 1000) {
      reject("Message must be less than 2,000 characters.");
    } else {
      resolve(data);
    }
  });
}

function sendContactFormNotification(data) {
  console.log("sendContactFormNotification");
  return new Promise(function (resolve, reject) {
    // Set up Firebase database
    const db = getFirestore();
    if (location.hostname === "localhost") connectFirestoreEmulator(db, "localhost", 8080);

    // Get collection Reference
    const colRef = collection(db, "mail");

    addDoc(colRef, {
      to: "support@telltouch.com.au",
      replyTo: data.email,
      template: {
        name: "notification-contact-us",
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          company: null,
          role: null,
          email: data.email,
          phoneNumber: data.phoneNumber,
          message: data.message,
          time: null,
          date: null,
          pdf: null,
          method: null,
          subject: data.subject,
          source: data.source,
        },
      },
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error.message);
      });
  });
}

function clearContactForm() {
  contactForm.reset();
}

//
// Competition Form
//
const competitionForm = document.getElementById("competition-form");

// Listen to form submits
if (competitionForm) competitionForm.addEventListener("submit", competitionFormSubmit);

function competitionFormSubmit(e) {
  e.preventDefault();
  const form = competitionForm;

  fetchCompetitionFormData(form)
    .then(validateCompetitionFormData)
    .then(sendCompetitionFormNotification)
    .then(() => {
      form.querySelector("#form-body").classList.add("d-none");
      form.querySelector("#insert-first-name").innerHTML = form.name.value.split(" ").slice(0, -1).join(" ");
      form.querySelector("#success-message").classList.remove("collapse");
      form.querySelector("#error-message").classList.add("collapse");
    })
    .catch((error) => {
      console.error(error);
      if (error.indexOf("PERMISSION_DENIED") == 0) {
        error = "Something in one of the values you entered is invalid. Sorry, we don't have any more information that this!";
      }
      form.querySelector("#error-message").textContent = error;
      form.querySelector("#error-message").classList.remove("collapse");
    });
}

function fetchCompetitionFormData(form) {
  console.log("fetchCompetitionFormData");
  return new Promise(function (resolve, reject) {
    resolve({
      firstName: form.name.value.split(" ").slice(0, -1).join(" "),
      lastName: form.name.value.split(" ").slice(-1).join(" "),
      company: form.company.value || null,
      role: form.role.value || null,
      email: form.email.value.trim().toLowerCase(),
      phoneNumber: form.phoneNumber.value.replace(/\D/g, "") || null,
      time: form.time.value || null,
      date: form.date.value || null,
      method: form.method.value || null,
      subject: form.subject.value || null,
      source: form.source.value.trim() || null,
    });
  });
}

function validateCompetitionFormData(data) {
  console.log("validateCompetitionFormData");
  return new Promise(function (resolve, reject) {
    if (data.subject == null) {
      reject("Select a subject.");
    } else if (data.firstName.length == 0 || data.lastName.length == 0) {
      reject("Enter your first and last names.");
    } else if (data.company == null) {
      reject("Enter your company or organisation name.");
    } else if (data.role == null) {
      reject("Enter your role within the company or organisation.");
    } else if (data.email.length == 0) {
      reject("Enter your email.");
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email.trim())) {
      reject("Enter a valid email.");
    } else if (data.phoneNumber.length < 10 || data.phoneNumber.length > 16) {
      reject("Enter a valid mobile number.");
    } else if (data.method == null) {
      reject("Please select if you would prefer the demo over Zoom or in-person.");
    } else {
      resolve(data);
    }
  });
}

function sendCompetitionFormNotification(data) {
  console.log("sendCompetitionFormNotification");
  return new Promise(function (resolve, reject) {
    // Set up Firebase database
    const db = getFirestore();
    if (location.hostname === "localhost") connectFirestoreEmulator(db, "localhost", 8080);

    // Get collection Reference
    const colRef = collection(db, "mail");

    addDoc(colRef, {
      to: "support@telltouch.com.au",
      replyTo: data.email,
      template: {
        name: "notification-competition-entry",
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          company: data.company,
          role: data.role,
          email: data.email,
          phoneNumber: data.phoneNumber,
          message: null,
          time: data.time,
          date: data.date,
          method: data.method,
          subject: data.subject,
          source: data.source,
        },
      },
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error.message);
      });
  });
}

//
// Actions on app download page.
//
const downloadButtons = document.getElementById("download-buttons");
if (downloadButtons) {
  // Detect OS
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    // Windows Phone must come first because its UA also contains "Android"
  } else if (/android/i.test(userAgent)) {
    document.getElementById("ios").classList.add("d-none");
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    document.getElementById("android").classList.add("d-none");
  }

  // Redirect to feedback form if org parameter is included in the URL
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.org) document.location = `https://${params.org}.telltouch.com.au/en/topics/new?location=${params.location || ""}`;
}

//
// Toggle graphs on the homepage
//
document.addEventListener("DOMContentLoaded", () => {
  const residentialButton = document.getElementById("residentialButton");
  const homeCareButton = document.getElementById("homeCareButton");
  const residentialSvg = document.getElementById("residential-non-compliance");
  const homeCareSvg = document.getElementById("homecare-non-compliance");
  const excludesEl = document.getElementById("excludes");

  const toggleGraphs = () => {
    if (residentialButton.checked) {
      residentialSvg.classList.remove("d-none");
      homeCareSvg.classList.add("d-none");
      excludesEl.classList.remove("invisible");
    } else if (homeCareButton.checked) {
      residentialSvg.classList.add("d-none");
      homeCareSvg.classList.remove("d-none");
      excludesEl.classList.add("invisible");
    }
  };

  if (residentialButton) {
    // Initial call to set the correct graph visibility
    toggleGraphs();

    // Add event listeners to radio buttons
    residentialButton.addEventListener("change", toggleGraphs);
    homeCareButton.addEventListener("change", toggleGraphs);
  }
});

//
// Enable Tooltips
//
[].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).map((el) => {
  return new Tooltip(el);
});

// Enable Popovers
[].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]')).map(function (el) {
  return new Popover(el);
});
